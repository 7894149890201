<template>
    <header class="page-title">
        <h1><i class="bi bi-user-circle"></i> Gmail連携</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else-if="gmail_extension_token === null">
            <div class="row mb-3 align-items-end">
                <div class="col-9">
                    <label class="form-label">Gmail 連携</label>
                    <div class="form-readonly">未使用</div>
                </div>
                <div class="col-9">
                    <button class="btn btn-primary" @click="generate()">利用開始</button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row mb-3 align-items-end">
                <div class="col-9">
                    <label class="form-label">Gmail 連携</label>
                    <div class="form-readonly">使用中</div>
                </div>
                <div class="col-9">
                    <label class="form-label">Gmail トークン</label>
                    <div class="form-readonly">{{ gmail_extension_token }}</div>
                </div>
                <div class="col-9">
                    <button class="btn btn-primary" @click="generate()">リセット</button>
                </div>
            </div>
            <button class="btn btn-outline-primary" @click="download()"><i class="bi bi-download"></i> 拡張機能ダウンロード</button>
        </template>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import { downloadBlob } from '@/utilities/download';

export default {
    name: 'ReportFollowUp',
    components: {
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            gmail_extension_token: null,
        }
    },
    mounted() {
        this.loading = true;

        this.$http.get('/me/extension')
        .then(response => {
            this.gmail_extension_token = response.data.gmail_extension_token;
        })
        .finally(() => {
            this.loading = false;
        })
    },
    methods: {
        generate() {
            this.loading = true;

            this.$http.put('/me/extension')
            .then(response => {
                this.gmail_extension_token = response.data.gmail_extension_token;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        download() {
            this.startScreenLoading();

            this.$http.get(`/download/extension`, {responseType: 'blob'})
            .then(response => {
                let filename = 'asmile_gmail_xxx.zip';
                if ('content-disposition' in response.headers) {
                    filename = response.headers['content-disposition'].replace((/attachment; filename=(.*)/u), '$1').replace('"', '').replace("'", "");
                }
                downloadBlob(response.data, filename);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
